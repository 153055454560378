import { logError, logEvent } from "@src/appV2/lib/analytics";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  type QueryObserverOptions,
} from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";

import { isDevelopmentNodeEnvironment } from "../environment";

export const defaultQueryOptions: QueryObserverOptions = {
  refetchOnWindowFocus: true,
  retry: 2,
  retryDelay: 1000,
  useErrorBoundary: isDevelopmentNodeEnvironment(),
};

export const cbhQueryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      const { meta: { logErrorMessage, userErrorMessage } = {} } = query;

      if (logErrorMessage) {
        logError(String(logErrorMessage), { error });
      }

      if (userErrorMessage) {
        enqueueSnackbar({
          variant: "error",
          message: userErrorMessage,
        });
      }
    },
    onSuccess: (_, query) => {
      const { meta: { logSuccessMessage, userSuccessMessage } = {} } = query;
      if (logSuccessMessage) {
        logEvent(String(logSuccessMessage));
      }

      if (userSuccessMessage) {
        enqueueSnackbar({
          variant: "success",
          message: userSuccessMessage,
        });
      }
    },
  }),
  mutationCache: new MutationCache({
    onError(error, _variables, _context, mutation) {
      const { meta: { logErrorMessage, userErrorMessage } = {} } = mutation;
      if (logErrorMessage) {
        logError(String(logErrorMessage), { error });
      }

      if (userErrorMessage) {
        enqueueSnackbar({
          variant: "error",
          message: userErrorMessage,
        });
      }
    },
    onSuccess(_data, _variables, _context, mutation) {
      const { meta: { logSuccessMessage, userSuccessMessage } = {} } = mutation;
      if (logSuccessMessage) {
        logEvent(String(logSuccessMessage));
      }

      if (userSuccessMessage) {
        enqueueSnackbar({
          variant: "success",
          message: userSuccessMessage,
        });
      }
    },
  }),
  defaultOptions: {
    queries: defaultQueryOptions,
  },
});
